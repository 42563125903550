/* eslint-disable no-unused-vars */
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { useUI } from '@components/ui/context'
import useSearch from '@commerce/product/use-search'
import useCustomer from '@shopify/customer/use-customer'
import { commerceEnabled, handleLocaleLink } from '@lib/utils/tools'
import { useCart } from 'lib/shopify/api/cart'
import Button from '@components/ui/Button'
import Icon from '@components/icons'
import { Text } from '@components/ui'
import { MULPASS_URL, APP_NAME, BRAND } from '@shopify/const'
import classNames from 'classnames'
import s from './Navbar.module.css'
import referralApi from 'templates/Pages/Brands/Referral/api'
import { Picture } from '@components/ui'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import SearchHistory, { storeSearchHistory } from './SearchHistory'
import useDefaultSearchItem from '@components/hooks/useDefaultSearchItem'
import PredictiveSearch from './PredictiveSearch'
import RecommendProducts from 'templates/Pages/Search/RecommendProducts'
import { useGlobal } from '../GlobalContext'
import UserPlus from '@components/icons/member/user_plus.svg'
import { useProfile } from '../ProfileContext'
import Icons from './icons'

const countItem = (count, item) => count + item.quantity

const Logo = ({ isHomeDark }) => {
  return (
    <svg
      className={cn(
        'h-[22px] w-[46px]',
        isHomeDark ? 'text-white' : 'text-[#005D8E]'
      )}
      width="658"
      height="314"
      viewBox="0 0 658 314"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.64 145.13C163.58 145.13 157.86 150.85 157.86 157.91C157.86 194.38 128.19 224.06 91.7095 224.06C55.2295 224.06 25.5595 194.39 25.5595 157.91C25.5595 121.43 55.2295 91.7602 91.7095 91.7602C107.8 91.7602 123.04 97.5302 134.97 107.87L95.2995 146.1C90.2195 151 90.0695 159.09 94.9695 164.17C97.4795 166.77 100.83 168.08 104.18 168.08C107.53 168.08 110.57 166.89 113.05 164.5L161.92 117.41C161.92 117.41 161.99 117.33 162.02 117.3C162.12 117.2 162.22 117.09 162.32 116.98C162.52 116.77 162.72 116.56 162.9 116.35C162.99 116.24 163.07 116.13 163.15 116.02C163.33 115.79 163.51 115.56 163.67 115.32C163.73 115.23 163.78 115.13 163.84 115.04C164.01 114.78 164.17 114.51 164.32 114.24C164.36 114.17 164.39 114.1 164.42 114.03C164.58 113.73 164.73 113.42 164.86 113.11C164.88 113.06 164.89 113.02 164.91 112.97C165.05 112.63 165.17 112.29 165.28 111.95C165.29 111.91 165.3 111.88 165.31 111.84C165.42 111.48 165.51 111.12 165.58 110.76C165.58 110.72 165.59 110.67 165.6 110.63C165.67 110.27 165.73 109.92 165.76 109.56C165.76 109.49 165.76 109.42 165.77 109.36C165.8 109.02 165.82 108.69 165.83 108.35C165.83 108.25 165.83 108.16 165.83 108.06C165.83 107.75 165.82 107.44 165.79 107.13C165.78 107.01 165.76 106.89 165.74 106.76C165.71 106.48 165.68 106.19 165.62 105.91C165.6 105.77 165.56 105.64 165.53 105.51C165.47 105.24 165.42 104.97 165.34 104.71C165.3 104.58 165.25 104.44 165.21 104.31C165.13 104.05 165.04 103.78 164.94 103.52C164.89 103.4 164.83 103.28 164.78 103.15C164.67 102.89 164.55 102.62 164.42 102.37C164.36 102.25 164.29 102.14 164.23 102.03C164.09 101.77 163.95 101.52 163.78 101.27C163.7 101.15 163.61 101.03 163.53 100.92C163.37 100.69 163.21 100.46 163.03 100.23C163.01 100.21 162.99 100.18 162.98 100.16C145.47 78.5802 119.5 66.2002 91.7295 66.2002C41.1595 66.2002 0.0195312 107.34 0.0195312 157.91C0.0195312 208.48 41.1595 249.62 91.7295 249.62C142.3 249.62 183.44 208.48 183.44 157.91C183.44 150.85 177.72 145.13 170.66 145.13H170.64Z"
        fill="currentColor"
      />
      <path
        d="M470.289 2.53031C446.979 -2.46969 425.789 1.51032 410.639 13.7603C399.079 23.1003 385.299 42.0703 385.299 79.3503V83.9703C385.299 84.1103 385.279 84.2403 385.279 84.3703C385.279 84.5003 385.299 84.6403 385.299 84.7703V236.83C385.299 243.89 391.019 249.61 398.079 249.61C405.139 249.61 410.859 243.89 410.859 236.83V97.1503H467.589C474.649 97.1503 480.369 91.4303 480.369 84.3703C480.369 77.3103 474.649 71.5903 467.589 71.5903H411.109C412.299 54.0103 417.619 40.9803 426.699 33.6403C433.089 28.4803 444.899 23.2203 464.919 27.5203C471.829 29.0003 478.619 24.6103 480.099 17.7003C481.579 10.8003 477.189 4.00031 470.279 2.52031L470.289 2.53031Z"
        fill="currentColor"
      />
      <path
        d="M649.03 67.1504C642.46 64.5604 635.04 67.7904 632.45 74.3604C630.92 78.2404 603.37 148.31 582.74 203.59L530.6 74.2605C527.96 67.7105 520.52 64.5404 513.96 67.1804C507.41 69.8204 504.24 77.2704 506.88 83.8204L569.62 239.46C568.28 243.24 567.05 246.77 565.95 249.99C552.63 289.1 530.39 288.28 527.55 288C527.55 288 527.55 288 527.54 288.01C527.22 287.99 526.9 287.96 526.58 287.96C519.51 287.96 513.82 293.69 513.77 300.77C513.72 307.77 519.2 313.4 526.39 313.57C526.4 313.57 526.41 313.57 526.43 313.57C526.45 313.57 526.47 313.57 526.49 313.57C526.52 313.57 526.55 313.57 526.58 313.57C526.67 313.57 526.76 313.56 526.85 313.56C541.02 313.38 573.35 307.57 590.16 258.23C606.73 209.59 655.74 84.9804 656.23 83.7204C658.82 77.1504 655.58 69.7305 649.01 67.1405L649.03 67.1504Z"
        fill="currentColor"
      />
      <path
        d="M333.929 66.2197C326.869 66.2197 321.149 71.9397 321.149 78.9997V180.83C321.149 204.67 301.749 224.07 277.909 224.07C254.069 224.07 234.669 204.67 234.669 180.83V78.9997C234.669 71.9397 228.949 66.2197 221.889 66.2197C214.829 66.2197 209.109 71.9397 209.109 78.9997V180.83C209.109 218.77 239.969 249.63 277.909 249.63C315.849 249.63 346.709 218.76 346.709 180.83V78.9997C346.709 71.9397 340.989 66.2197 333.929 66.2197Z"
        fill="currentColor"
      />
    </svg>
  )
}

const MenuItemIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clip-path="url(#clip0_4440_10186)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.83717 6.31175C3.39597 3.58954 5.59488 1.62385 8.1439 1.62385C10.7276 1.62385 12.9516 3.64341 13.4725 6.42333C13.0989 6.68229 12.8542 7.11418 12.8542 7.60323V9.70098C12.8542 10.4933 13.4964 11.1355 14.2887 11.1355C15.081 11.1355 15.7233 10.4933 15.7233 9.70098V7.60323C15.7233 6.95291 15.2905 6.40366 14.6973 6.22771C14.0931 2.93503 11.4251 0.38385 8.1439 0.38385C4.83287 0.38385 2.14628 2.98163 1.57452 6.3177C1.10205 6.5524 0.777344 7.0399 0.777344 7.60323V9.70098C0.777344 10.4933 1.41962 11.1355 2.2119 11.1355C2.23173 11.1355 2.25147 11.1351 2.2711 11.1343C3.22491 13.0406 4.95215 14.452 7.02021 14.8324C7.18055 15.007 7.41078 15.1165 7.66658 15.1165H8.4404C8.92487 15.1165 9.31761 14.7238 9.31761 14.2393C9.31761 13.7548 8.92487 13.3621 8.4404 13.3621H7.66658C7.44464 13.3621 7.24195 13.4445 7.08744 13.5804C5.49526 13.2323 4.13598 12.1112 3.36652 10.5525C3.54245 10.3143 3.64646 10.0198 3.64646 9.70098V7.60323C3.64646 7.0351 3.31619 6.5441 2.83717 6.31175ZM2.40646 7.60323V9.70098C2.40646 9.80843 2.31935 9.89553 2.2119 9.89553C2.10445 9.89553 2.01734 9.80843 2.01734 9.70098V7.60323C2.01734 7.49578 2.10445 7.40868 2.2119 7.40868C2.31935 7.40868 2.40646 7.49578 2.40646 7.60323ZM14.4833 9.70098V7.60323C14.4833 7.49578 14.3962 7.40868 14.2887 7.40868C14.1813 7.40868 14.0942 7.49578 14.0942 7.60323V9.70098C14.0942 9.80843 14.1813 9.89553 14.2887 9.89553C14.3962 9.89553 14.4833 9.80843 14.4833 9.70098Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_4440_10186">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const HeaderPc = ({
  onRegistrations,
  searchValue,
  locale,
  headerSetting,
  setShowSearch,
  shopCommon,
  showSearch,
  handleSearch,
  setSearchValue,
  navSearch,
  recommendProducts,
  handle,
  userCenterSetting,
  simpleUserCenterSetting,
  customer,
  handleMenuUrl,
  handleReferralTrack,
  menuTheme,
  setSidebarView,
  toggleSidebar,
  itemsCount,
  livestream,
  setLived,
  lived,
  setCurrent,
  handleAddHash,
  current,
  currentChild,
  setCurrentChild,
  setCurrentSubTab,
  currentSubTab,
  CART,
  defaultSearchItem,
  searchResult,
  searching,
  handleHideMega,
  is_custom_storefront,
  isHomeDark,
  nickname,
  username,
  profile,
}) => {
  const [isComposing, setIsComposing] = useState(false)
  const customerDropMaskRef = useRef(null)
  const [customerDropMaskTop, setCustomerDropMaskTop] = useState(0)
  const { setRegistrationsPopup, setRegistrationsModelStatus } = useUI()
  // 优化代码，减少重复和提高可读性
  const customerDropMenuStyle = useMemo(() => {
    const settings = ['signIn', 'signUp', 'orderTrack', 'get$40'].map(
      (key) => userCenterSetting?.[key]?.length || 0
    )
    const signLength = settings[0] + settings[1]
    const minWidth = Math.max(signLength, settings[2], settings[3]) * 10
    return minWidth > 156 ? { minWidth: `${minWidth}px` } : {}
  }, [userCenterSetting])

  const loggedInButtons = useMemo(() => {
    return Array.isArray(userCenterSetting?.loggedInButtons) &&
      profile?.activated
      ? userCenterSetting?.loggedInButtons?.filter(
          (item) => item?.link !== '/account/management'
        )
      : userCenterSetting?.loggedInButtons
  }, [profile?.activated, userCenterSetting?.loggedInButtons])

  useEffect(() => {
    const headerElement = document.querySelector('#headerV3')
    if (headerElement) {
      // const position = headerElement.getBoundingClientRect()
      const headerHeight = headerElement.offsetHeight
      setCustomerDropMaskTop(headerHeight)
    }
  }, [])

  return (
    <div className={classNames(s.headerPc)}>
      <div className="flex">
        <a
          className={s.headerLogo}
          href={`${`/${locale === 'us' ? '' : locale}`}?ref=logo`}
          title={`${BRAND} ${locale}`}
          tag="Auto-00000101"
        >
          <Logo isHomeDark={isHomeDark} />
        </a>
      </div>
      <div className="flex flex-1">
        <nav className={s.mainNav}>
          <ul className="flex">
            {headerSetting &&
              headerSetting.map((item, index) => {
                return (
                  item.type != 'links' &&
                  item.type != 'live' &&
                  item.pcHide != true && (
                    <MainNavLink
                      key={index}
                      item={item}
                      index={index}
                      handleMenuUrl={handleMenuUrl}
                      setCurrent={setCurrent}
                      handleAddHash={handleAddHash}
                      current={current}
                      currentChild={currentChild}
                      setCurrentChild={setCurrentChild}
                      setCurrentSubTab={setCurrentSubTab}
                      currentSubTab={currentSubTab}
                      shopCommon={shopCommon}
                      showSearch={showSearch}
                      handleHideMega={handleHideMega}
                      is_custom_storefront={is_custom_storefront}
                    />
                  )
                )
              })}
          </ul>
          <ul className="flex">
            {headerSetting &&
              headerSetting.map((item, index) => {
                return (
                  item.type == 'links' &&
                  item.pcHide != true && (
                    <MainNavLink
                      key={index}
                      item={item}
                      index={index}
                      handleMenuUrl={handleMenuUrl}
                      setCurrent={setCurrent}
                      handleAddHash={handleAddHash}
                      current={current}
                      currentChild={currentChild}
                      setCurrentChild={setCurrentChild}
                      setCurrentSubTab={setCurrentSubTab}
                      currentSubTab={currentSubTab}
                      shopCommon={shopCommon}
                      handleHideMega={handleHideMega}
                      is_custom_storefront={is_custom_storefront}
                    />
                  )
                )
              })}
          </ul>
        </nav>
      </div>
      <div className="flex items-center gap-2">
        <div className={s.headerMenuIconWrap}>
          <div
            className={cn(s.headerMenuIcon, 'cursor-pointer')}
            tag="Auto-00000110"
            onClick={() => {
              setShowSearch(!showSearch)
              /* if (navSearch) {
                  if (showSearchBoard) {
                    setShowSearchBoard(false)
                  } else {
                    setDropDownBoxContent('')
                    setDropDownSmallBoxContent('')
                    setShowSearchBoard(true)
                    onSearchClick()
                  }
                } else {
                  setShowSearch(!showSearch)
                } */
            }}
          >
            <Icon
              iconKey={'navsearch'}
              width={20}
              height={20}
              className={s.icon}
            ></Icon>
          </div>
          <div className={`${s.megaWrapper} ${showSearch ? s.actived : ''}`}>
            <i className={s.megaMask} onClick={() => setShowSearch(false)}></i>
            <div className={cn(s.megaContent, 'h-[544px]')}>
              <div className={`flex justify-center ${s.fullWidth}`}>
                <div className={s.megaSearchWrap}>
                  <div className={s.megaSearchInputWrap}>
                    <Icon
                      iconKey={searching ? 'loading' : 'navsearch'}
                      width={20}
                      height={20}
                      className={cn(s.icon, {
                        'animate-spin': searching,
                      })}
                      onClick={() => {
                        if (!searching)
                          handleSearch(searchValue || defaultSearchItem)
                      }}
                    ></Icon>
                    <input
                      className={s.megaSearchInput}
                      type="text"
                      placeholder={
                        defaultSearchItem || shopCommon?.['search_placeholder']
                      }
                      value={searchValue}
                      onKeyDown={(e) => {
                        e.key == 'Enter' &&
                          !isComposing &&
                          handleSearch(e.target.value || defaultSearchItem)
                      }}
                      onCompositionStart={() => setIsComposing(true)}
                      onCompositionEnd={() => setIsComposing(false)}
                      onChange={(e) => {
                        setSearchValue(e.target.value)
                      }}
                    ></input>
                    <Icon
                      className="outline-none"
                      iconKey={'close'}
                      width={20}
                      height={20}
                      onClick={() => setSearchValue('')}
                    ></Icon>
                  </div>
                  {searchValue ? (
                    <PredictiveSearch
                      searchResult={searchResult}
                      searchTerm={searchValue}
                      viewMore={navSearch?.viewMore}
                      resultTitle={navSearch?.result}
                    />
                  ) : null}
                  {searchValue &&
                  !searching &&
                  searchResult?.items?.length === 0 ? (
                    <Text
                      variant="paragraph"
                      className="my-[16px] w-full text-left text-[16px] font-medium text-[#777] "
                    >
                      {navSearch.noResult}
                    </Text>
                  ) : null}
                  {!searchValue ||
                  (searchValue && !searchResult?.items?.length) ? (
                    <RecommendProducts
                      title={navSearch?.recommend_title}
                      dataSource={recommendProducts}
                      pageHandle={handle}
                    />
                  ) : null}
                  {!searchValue && (
                    <SearchHistory
                      handleClick={handleSearch}
                      locale={locale}
                      title={navSearch?.searchHistory}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <LivePart
          headerSetting={headerSetting}
          handleMenuUrl={handleMenuUrl}
          livestream={livestream}
          setLived={setLived}
          lived={lived}
          is_custom_storefront={is_custom_storefront}
        />

        {userCenterSetting && (
          <div
            className={`${s.headerMenuIcon} ${s.headerUserIcon}`}
            tag="Auto-00000111"
            onMouseEnter={() => {
              customerDropMaskRef?.current?.classList?.add('!block')
              document.body.style.overflow = 'hidden'
            }}
          >
            {profile && (
              <a
                href={`${MULPASS_URL}/account/?app=${APP_NAME[locale]}`}
                aria-label="account"
              ></a>
            )}
            <>
              {profile?.isPlus ? (
                <div className="relative h-5 w-5">
                  <UserPlus
                    width={36}
                    height={36}
                    className="absolute left-1/2 top-1/2 -ml-[19px] -mt-[18px]"
                  />
                </div>
              ) : (
                <Icon
                  iconKey={'navuser'}
                  width={20}
                  height={20}
                  className={s.icon}
                ></Icon>
              )}
              {profile && !profile?.activated && (
                <Icon
                  iconKey={'badge'}
                  width={20}
                  height={20}
                  className={'absolute -right-1 top-0 z-[1]'}
                ></Icon>
              )}
            </>
            <div
              className={cn(s.customerDropMask, {
                '!hidden': showSearch,
              })}
              style={{ top: customerDropMaskTop }}
              ref={customerDropMaskRef}
              onMouseEnter={() => {
                customerDropMaskRef?.current?.classList?.remove('!block')
                document.body.style.overflow = ''
              }}
            >
              <div className={s.headerPc}>
                <div
                  className={s.customerDropMenuList}
                  style={{ width: 300 }}
                  onMouseEnter={() => {
                    customerDropMaskRef?.current?.classList?.add('!block')
                    document.body.style.overflow = 'hidden'
                  }}
                  onMouseLeave={() => {
                    customerDropMaskRef?.current?.classList?.remove('!block')
                    document.body.style.overflow = ''
                  }}
                >
                  {!profile ? (
                    <div className={s.customerDropMenuListInner}>
                      <Text
                        className={s.couponDetailsText}
                        html={userCenterSetting?.couponDetailsText}
                      />
                      <Button
                        variant="plain"
                        className={cn(
                          s.commonBtn,
                          'my-2 w-[214px] min-xl:my-4'
                        )}
                        onClick={() => {
                          setRegistrationsPopup(true)
                          setRegistrationsModelStatus('main')
                        }}
                      >
                        {userCenterSetting?.['signUp']}
                      </Button>
                      <Text
                        className="text-[14px] font-medium leading-[1.2] text-[#333] [&_span]:text-eufy-color [&_span]:underline"
                        html={userCenterSetting?.existingUserText}
                        onClick={(e) => {
                          if (e?.target?.id === 'mobLogin') {
                            e.preventDefault()
                            setRegistrationsPopup(true)
                            setRegistrationsModelStatus('quickLogin')
                          }
                        }}
                      />
                      <div className="my-4 h-[1px] bg-[#EEEEEE]"></div>
                      {/* <div className={s.customerDropMenuItem}>
                    <a
                      className={s.customerDropMenuLink}
                      tag="Auto-000001111"
                      href={handleMenuUrl(
                        `${MULPASS_URL}/?app=${APP_NAME[locale]}`,
                        is_custom_storefront,
                        'ref',
                        `navimenu_${headerSetting?.length + 2}_1_copy`
                      )}
                      onClick={(e) => onRegistrations(e, 'signIn')}
                      aria-label={userCenterSetting?.['signIn']}
                    >
                      {userCenterSetting?.['signIn']}
                    </a>
                    <span className={s.customerDropMenuLine}>/</span>
                    <a
                      className={s.customerDropMenuLink}
                      tag="Auto-000001112"
                      href={handleMenuUrl(
                        `${MULPASS_URL}/?app=${APP_NAME[locale]}&tab=register`,
                        is_custom_storefront,
                        'ref',
                        `navimenu_${headerSetting?.length + 2}_2_copy`
                      )}
                      onClick={(e) => onRegistrations(e, 'signUp')}
                      aria-label={userCenterSetting?.['signUp']}
                    >
                      {userCenterSetting?.['signUp']}
                    </a>
                  </div> */}
                      <div className={s.customerDropMenuItemWrap}>
                        {userCenterSetting?.['orderTrack'] && (
                          <div className={s.customerDropMenuItem}>
                            <div className="flex items-center gap-2">
                              <Icon
                                iconKey={'tracker'}
                                width={20}
                                height={20}
                              ></Icon>
                              <a
                                className={s.customerDropMenuLink}
                                tag="Auto-000001113"
                                href={handleMenuUrl(
                                  userCenterSetting?.['orderTrackHref'],
                                  is_custom_storefront,
                                  'ref',
                                  `navimenu_${headerSetting?.length + 2}_3_copy`
                                )}
                              >
                                {userCenterSetting?.['orderTrack']}
                              </a>
                            </div>
                            <div className={s.customerDropMenuItemArrowIcon}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5.09029 9.09261C4.86574 9.31716 4.86574 9.68123 5.09029 9.90578C5.31484 10.1303 5.67891 10.1303 5.90346 9.90578L8.90346 6.90578C9.12801 6.68123 9.12801 6.31716 8.90346 6.09261L5.90346 3.09261C5.67891 2.86806 5.31484 2.86806 5.09029 3.09261C4.86574 3.31716 4.86574 3.68123 5.09029 3.90578L7.6837 6.49919L5.09029 9.09261Z"
                                  fill="#333"
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                        {userCenterSetting?.['get$40'] && (
                          <div className={s.customerDropMenuItem}>
                            <div className="flex items-center gap-2">
                              <Icon
                                iconKey={'refer'}
                                width={20}
                                height={20}
                                className="flex-shrink-0"
                              ></Icon>
                              <a
                                className={s.customerDropMenuLink}
                                tag="Auto-000001114"
                                onClick={handleReferralTrack}
                                href={handleMenuUrl(
                                  userCenterSetting?.['referralHref'],
                                  is_custom_storefront,
                                  'ref',
                                  `navimenu_${headerSetting?.length + 4}_copy`
                                )}
                              >
                                {userCenterSetting?.['get$40']}
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={s.loggedInDropMenuWrap}>
                      <div
                        className={s.loggedInUserInfo}
                        style={{
                          background:
                            profile?.isPlus &&
                            'linear-gradient(116deg, rgba(241, 234, 219, 0.80) 0%, rgba(217, 166, 106, 0.80) 145.94%), #FFF',
                        }}
                      >
                        <div className="relative">
                          <Text className={s.userAvatar} html={nickname} />
                          {profile?.isPlus && (
                            <Picture
                              className={s.plusIcon}
                              source={userCenterSetting?.plusIcon}
                              imgClassName="h-full"
                            />
                          )}
                        </div>
                        <Text className={s.userName} html={username} />
                        <Picture
                          className={s.logoIcon}
                          source={userCenterSetting?.logoIcon}
                        />
                      </div>
                      <div className="overflow-hidden rounded-t-2xl px-4 pb-6 pt-4">
                        <div className={s.loggedInButtonsWrap}>
                          {loggedInButtons?.map((button, index) => {
                            return (
                              <a
                                key={index + 'loggedInButtonItem'}
                                className={s.loggedInButtonItem}
                                href={`${
                                  button?.isEufyDomain ? '' : MULPASS_URL
                                }/${locale}${button?.link}?ref=navimenu_${
                                  headerSetting?.length + index
                                }_copy`}
                              >
                                <div className={s.loggedInButtonItemInner}>
                                  <Text
                                    html={button?.icon}
                                    className={s.loggedInButtonItemIcon}
                                  />
                                  <Text
                                    html={button?.label}
                                    className={s.loggedInButtonLabel}
                                  />
                                  {button?.extraIcon &&
                                    profile &&
                                    !profile?.activated && (
                                      <Icon
                                        iconKey={'badge'}
                                        width={18}
                                        height={18}
                                      ></Icon>
                                      // <Picture
                                      //   source={button?.extraIcon}
                                      //   className={s.loggedInButtonExtraIcon}
                                      // />
                                    )}
                                </div>
                                <div
                                  className={s.customerDropMenuItemArrowIcon}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.09029 9.09261C4.86574 9.31716 4.86574 9.68123 5.09029 9.90578C5.31484 10.1303 5.67891 10.1303 5.90346 9.90578L8.90346 6.90578C9.12801 6.68123 9.12801 6.31716 8.90346 6.09261L5.90346 3.09261C5.67891 2.86806 5.31484 2.86806 5.09029 3.09261C4.86574 3.31716 4.86574 3.68123 5.09029 3.90578L7.6837 6.49919L5.09029 9.09261Z"
                                      fill="#333"
                                    />
                                  </svg>
                                </div>
                              </a>
                            )
                          })}
                        </div>
                        <div className="my-3 ml-3 h-[1px] bg-[#EEEEEE] min-xl:my-4" />
                        <div className={s.loggedInButtonItem}>
                          <div className={s.loggedInButtonItemInner}>
                            <Text
                              html={userCenterSetting?.signOutIcon}
                              className={s.loggedInButtonItemIcon}
                            />
                            <a
                              dangerouslySetInnerHTML={{
                                __html: userCenterSetting?.signOut,
                              }}
                              className={s.loggedInButtonLabel}
                              href={`${MULPASS_URL}/account/logout?app=${APP_NAME[locale]}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {simpleUserCenterSetting && (
          <div
            className={`${s.headerMenuIcon} ${s.headerUserIcon}`}
            tag="Auto-00000111"
          >
            (
            <a
              href={simpleUserCenterSetting?.loginUrl}
              aria-label="account"
            ></a>
            )
            <Icon
              iconKey={'navuser'}
              width={20}
              height={20}
              className={s.icon}
            ></Icon>
          </div>
        )}

        <div className={s.headerMenuIcon}>
          {BRAND && (
            <>
              {CART && (
                <>
                  {
                    <Button
                      className={classNames(s.item, {
                        [s.iconLight]: menuTheme,
                      })}
                      variant="naked"
                      tag="Auto-00000112"
                      onClick={() => {
                        setSidebarView('CART_VIEW')
                        toggleSidebar()
                      }}
                      aria-label={`Cart items: ${itemsCount}`}
                    >
                      <Icon
                        iconKey={'carteufy'}
                        width={20}
                        height={20}
                        className={s.icon}
                      ></Icon>
                      {itemsCount > 0 && (
                        <span className={s.bagCount}>{itemsCount}</span>
                      )}
                    </Button>
                  }
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const LivePart = ({
  headerSetting,
  handleMenuUrl,
  livestream,
  setLived,
  lived,
  is_custom_storefront,
}) => {
  const icsDate2jsDate = (date) => {
    let year = parseInt(date.substr(0, 4))
    let month = parseInt(date.substr(4, 2)) - 1
    let day = parseInt(date.substr(6, 2))
    let hour = parseInt(date.substr(9, 2))
    let minute = parseInt(date.substr(11, 2))
    let second = parseInt(date.substr(13, 2))
    return new Date(Date.UTC(year, month, day, hour, minute, second))
  }

  return (
    headerSetting &&
    headerSetting.map((item, index) => {
      const url = handleMenuUrl(
        item.href,
        is_custom_storefront,
        'ref',
        `navimenu_${index + 1}_copy`
      )

      if (item.type == 'live') {
        const { durations } = item
        const { list = [] } = livestream || {}
        if (list.length) {
          list.map((item) => {
            const s = item.reminder_start
            const e = item.reminder_end
            const now = new Date().getTime()
            const startTime = icsDate2jsDate(s)
            const endTime = icsDate2jsDate(e)
            if (
              (now >= startTime && !e) ||
              (now >= startTime && now <= endTime)
            ) {
              setLived(true)
            }
          })
        } else {
          durations &&
            durations.map(({ start, end }) => {
              if (start) {
                const s = start.replace(/-/g, '/')
                const e = end.replace(/-/g, '/')
                const now = new Date().getTime()
                const startTime = new Date(s).getTime()
                const endTime = new Date(e).getTime()
                if (
                  (now >= startTime && !e) ||
                  (now >= startTime && now <= endTime)
                ) {
                  setLived(true)
                }
              }
            })
        }
      }
      return (
        item.type == 'live' && (
          <div
            className={classNames(s.livePart, {
              [s['actived']]: lived,
            })}
            alt={item.label}
            key={index}
          >
            <a href={url} tag="Auto-00000113">
              <span className={s.liveIcon}>
                <Icon iconKey="navlive" width={20} height={20} />
              </span>
              <span className={s.liveIcon}>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 5C2.89543 5 2 5.89543 2 7V21C2 22.1046 2.89543 23 4 23H18C19.1046 23 20 22.1046 20 21V17.7694L23.7889 19.8095C24.7882 20.3476 26 19.6238 26 18.4888V9.51148C26 8.37647 24.7882 7.65267 23.7889 8.19078L20 10.2309V7C20 5.89543 19.1046 5 18 5H4Z"
                    fill="#F84D4F"
                  />
                </svg>
                <i className={classNames(s.lineV, s.lineV1)}></i>
                <i className={classNames(s.lineV, s.lineV2)}></i>
                <i className={classNames(s.lineV, s.lineV3)}></i>
              </span>
            </a>
          </div>
        )
      )
    })
  )
}

const MainNavLink = ({
  item,
  index,
  handleMenuUrl,
  setCurrent,
  handleAddHash,
  handleHideMega,
  current,
  currentChild,
  setCurrentChild,
  setCurrentSubTab,
  currentSubTab,
  shopCommon,
  showSearch,
  is_custom_storefront,
}) => {
  const url = handleMenuUrl(
    item.href,
    is_custom_storefront,
    'ref',
    `navimenu_${index + 1}_copy`
  )
  return (
    <li
      className={s[`mainNav${item.mode}Item`]}
      onMouseEnter={() => {
        if (!showSearch) {
          setCurrent(index)
          handleAddHash(`navimenu_${index + 1}_copy`)
        }
      }}
      onMouseLeave={() => handleHideMega()}
      tag={`Auto-0000010${index + 2}`}
    >
      {
        <a
          className={`${s.mainNavLink} ${current == index ? s.actived : ''}`}
          href={url ? url : undefined}
        >
          <span className={s.mainNavLinkText}>{item.label}</span>
          {item.tag ? <span className={s.headerTag}>{item.tag}</span> : ''}
        </a>
      }

      {item.type != 'link' && (
        <MegaWrapper
          item={item}
          current={current}
          index={index}
          handleHideMega={handleHideMega}
          currentChild={currentChild}
          setCurrentChild={setCurrentChild}
          setCurrentSubTab={setCurrentSubTab}
          handleMenuUrl={handleMenuUrl}
          currentSubTab={currentSubTab}
          shopCommon={shopCommon}
          is_custom_storefront={is_custom_storefront}
        />
      )}
    </li>
  )
}

const MegaWrapper = ({
  item,
  current,
  index,
  handleHideMega,
  currentChild,
  setCurrentChild,
  setCurrentSubTab,
  handleMenuUrl,
  currentSubTab,
  shopCommon,
  is_custom_storefront,
}) => {
  const { type, mode, tabs, links, group, more } = item
  return (
    <>
      <div className={`${s.megaWrapper} ${current == index ? s.actived : ''}`}>
        <i className={s.megaMask} onMouseEnter={() => handleHideMega()}></i>
        <div className={s.megaContent}>
          <div
            className={cn(
              `${mode == 'small' ? '' : s.fullWidth} ${s.megaContentInner}`,
              s[`megaContentInner${type}`]
            )}
          >
            {type == 'tab' && (
              <>
                <aside className={s.megaTabSide}>
                  <div className={s.sideArea}>
                    <ol className={s.megaTabList}>
                      {tabs &&
                        tabs.map((tab, idx) => {
                          return (
                            <li
                              className={`${s.megaTabItem} ${
                                idx === currentChild ? s.actived : ''
                              }`}
                              key={idx}
                            >
                              {tab.href ? (
                                <a
                                  href={handleMenuUrl(
                                    tab.href,
                                    is_custom_storefront,
                                    'ref',
                                    `navimenu_${index + 1}_${idx + 1}_copy`
                                  )}
                                >
                                  <span
                                    onMouseEnter={() => {
                                      setCurrentChild(idx)
                                      setCurrentSubTab(0)
                                    }}
                                  >
                                    {tab.label}
                                  </span>
                                </a>
                              ) : (
                                <span
                                  onMouseEnter={() => {
                                    setCurrentChild(idx)
                                    setCurrentSubTab(0)
                                  }}
                                >
                                  {tab.label}
                                </span>
                              )}
                            </li>
                          )
                        })}
                    </ol>
                  </div>
                  {links && links.length && (
                    <div className={s.megaTabLinks}>
                      {links.map((link, idx) => {
                        return (
                          <a
                            className={s.megaTabLink}
                            key={idx}
                            href={handleMenuUrl(
                              link.href,
                              is_custom_storefront,
                              'ref',
                              `navimenu_${index + 1}_${
                                tabs?.length + idx + 1
                              }_copy`
                            )}
                          >
                            {link.label}
                          </a>
                        )
                      })}
                    </div>
                  )}
                </aside>
                <div
                  className={`${s.megaTabContent} ${
                    s[`megaTab${mode || 'normal'}Content`]
                  }`}
                >
                  {tabs?.map((tab, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`${s.megaTabContentItem} ${
                          currentChild == idx ? s.actived : ''
                        }`}
                      >
                        {tab.collects && (
                          <div className={s.megaProductCollect}>
                            <MegaCollectContain
                              tab={tab}
                              index={0}
                              refidx={idx + 1}
                              current={current}
                              shopCommon={shopCommon}
                              handleMenuUrl={handleMenuUrl}
                              is_custom_storefront={is_custom_storefront}
                            />
                          </div>
                        )}
                        <div className={s.megaSubtabContain}>
                          <ul className={s.megaSubtabList}>
                            {tab.tabs &&
                              tab.tabs.map((subtab, subidx) => {
                                return (
                                  <li
                                    key={subidx}
                                    className={`${s.megaSubTabItem} ${
                                      currentSubTab == subidx ? s.actived : ''
                                    }`}
                                    onMouseEnter={() => {
                                      setCurrentSubTab(subidx)
                                    }}
                                  >
                                    {subtab.label}
                                  </li>
                                )
                              })}
                          </ul>
                        </div>
                        {tab.list && (
                          <MegaProductContain
                            tab={tab}
                            index={0}
                            seeMore={more}
                            refidx={idx + 1}
                            currentSubTab={currentSubTab}
                            current={current}
                            handleMenuUrl={handleMenuUrl}
                            shopCommon={shopCommon}
                            is_custom_storefront={is_custom_storefront}
                          />
                        )}
                        {tab.tabs &&
                          tab.tabs.map((subtab, subidx) => {
                            return (
                              <MegaProductContain
                                key={subidx}
                                tab={subtab}
                                seeMore={tab.more}
                                index={subidx}
                                refidx={`${idx + 1}_${subidx + 1}`}
                                currentSubTab={currentSubTab}
                                current={current}
                                handleMenuUrl={handleMenuUrl}
                                shopCommon={shopCommon}
                                is_custom_storefront={is_custom_storefront}
                              />
                            )
                          })}
                      </div>
                    )
                  })}
                </div>
              </>
            )}
            {type == 'links' && (
              <>
                <div className={s.megaLinksContent}>
                  {group &&
                    group.map((gp, idx) => {
                      return (
                        <div key={idx} className={s.megaLinksGroup}>
                          <p className={s.megaGroupTitle}>{gp.label}</p>
                          {gp.links &&
                            gp.links.map((link, lidx) => {
                              return (
                                <a
                                  key={lidx}
                                  className={s.megaGroupLink}
                                  href={handleMenuUrl(
                                    link.href,
                                    is_custom_storefront,
                                    'ref',
                                    `navimenu_${index + 1}_${idx + 1}_${
                                      lidx + 1
                                    }_copy`
                                  )}
                                  rel="noopener noreferrer"
                                  {...(link.openNew && { target: '_blank' })}
                                >
                                  {link.label}
                                </a>
                              )
                            })}
                        </div>
                      )
                    })}
                </div>
              </>
            )}
            {type == 'deals' && (
              <div className="flex w-full justify-center gap-x-4">
                {links &&
                  links.map((link, idx) => {
                    return (
                      <div
                        key={idx}
                        className={classNames(s.megaDealsItem, {
                          'text-[#fff]': link.theme === 'white',
                        })}
                      >
                        <div className={s.megaDealsImgWrap}>
                          <Picture
                            imgClassName={classNames({
                              [s.megaDealsImg]: links.length > 1,
                              [s.megaDealsFullImg]: links.length === 1,
                            })}
                            source={links.length > 1 ? link.img : link.img_full}
                          ></Picture>
                        </div>
                        <div
                          className={classNames(s.megaDealsInfoWrap, {
                            [s.megaDealsFullInfo]: links.length === 1,
                          })}
                        >
                          <a
                            className={s.navBarFullLink}
                            href={handleMenuUrl(
                              link.href,
                              is_custom_storefront,
                              'ref',
                              `navimenu_${index + 1}_1_${idx + 1}_copy`
                            )}
                          ></a>
                          <p className={s.dealsTitle}>{link.title}</p>
                          <a
                            className={
                              link.theme === 'white'
                                ? s.linkBtnWhite
                                : s.linkBtn
                            }
                            href={handleMenuUrl(
                              link.href,
                              is_custom_storefront,
                              'ref',
                              `navimenu_${index + 1}_1_${idx + 1}_copy`
                            )}
                          >
                            {shopCommon.learn_more}
                          </a>
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const MegaCollectContain = ({
  tab,
  refidx,
  handleMenuUrl,
  current,
  shopCommon,
  is_custom_storefront,
}) => {
  const { collects } = tab
  return (
    <ul className={s.megaProductCollectList}>
      {collects &&
        collects.map((item, idx) => {
          return (
            <li className={s.metaProductCollectItem} key={idx}>
              <a
                href={handleMenuUrl(
                  item.href,
                  is_custom_storefront,
                  'ref',
                  `navimenu_${current + 1}_${refidx}_${idx + 1}_img`
                )}
              >
                <Picture
                  imgClassName={s.megaProductCollectImg}
                  loading="lazy"
                  source={item.img}
                  alt={item.title}
                  imgWidth={130}
                  imgHeight={84}
                />
              </a>
              <div className={s.megaProductCollectInfo}>
                <a
                  href={handleMenuUrl(
                    item.href,
                    is_custom_storefront,
                    'ref',
                    `navimenu_${current + 1}_${refidx}_${idx + 1}_copy`
                  )}
                >
                  <p className={s.megaProductCollectTitle}>{item.title}</p>
                </a>
                <p className={s.megaProductCollectDescription}>
                  {item.description}
                </p>
              </div>
              <div className={s.megaProductCollectFeature}>
                <p>{shopCommon.feature_products}</p>
                {item.products &&
                  item.products.map((product, i) => (
                    <a
                      key={i}
                      href={handleMenuUrl(
                        product.href,
                        is_custom_storefront,
                        'ref',
                        `navimenu_${current + 1}_${refidx}_${idx + 1}_${
                          i + 1
                        }_copy`
                      )}
                    >
                      {product.label}
                    </a>
                  ))}
              </div>
              <div className={s.megaProductCollectMore}>
                {item.all && (
                  <a
                    href={handleMenuUrl(
                      item.all?.href,
                      is_custom_storefront,
                      'ref',
                      `navimenu_${current + 1}_${refidx}_${idx + 1}_all_copy`
                    )}
                    dangerouslySetInnerHTML={{
                      __html: item.all?.label,
                    }}
                  ></a>
                )}
                {item.more && (
                  <a
                    href={handleMenuUrl(
                      item.more?.href,
                      is_custom_storefront,
                      'ref',
                      `navimenu_${current + 1}_${refidx}_${idx + 1}_more_copy`
                    )}
                    dangerouslySetInnerHTML={{
                      __html: item.more?.label,
                    }}
                  ></a>
                )}
              </div>
            </li>
          )
        })}
    </ul>
  )
}

const MegaProductContain = ({
  tab,
  index,
  refidx,
  seeMore,
  currentSubTab,
  current,
  handleMenuUrl,
  shopCommon,
  is_custom_storefront,
}) => {
  const { list, links, more } = tab
  const moreLink = more || seeMore
  const listLen = list && list.length > 4 ? 4 : list.length
  const linksLen = (links && links.length) || 0
  return (
    <div
      className={`${s.megaProductContain} ${
        currentSubTab == index ? s.actived : ''
      }`}
    >
      <ul className={s.megaProductList}>
        {list &&
          list.map((item, idx) => {
            // if (idx > 3) return
            return (
              <li key={idx} className={s.megaProductItem}>
                <a
                  href={handleMenuUrl(
                    item.href || `/products/${item.sku}`,
                    is_custom_storefront,
                    'ref',
                    `navimenu_${current + 1}_${refidx}_${idx + 1}_img`
                  )}
                >
                  {item.imgtype === 'bg' ? (
                    <div
                      className={cn(
                        s.megaProductImg,
                        'h-[175px] bg-[length:100%] bg-center bg-no-repeat min-xl:h-[124px]'
                      )}
                      style={{ backgroundImage: `url(${item.img})` }}
                    ></div>
                  ) : (
                    <Picture
                      className={s.megaProductImg}
                      imgClassName={s.megaProductImg}
                      loading="lazy"
                      source={item.img}
                      alt={item.title || item.label || ''}
                      imgWidth={180}
                      imgHeight={180}
                    />
                  )}
                  <div className={s.megaProductInfo}>
                    <p className={s.megaProductTitle}>
                      {item.title || item.label}
                    </p>
                    {item.description && (
                      <Text
                        className="mt-[4px] text-[12px] leading-[1.2] text-[#323232]"
                        html={item.description}
                      />
                    )}
                    <p className={s.megaProductTag}>{item.tag}</p>
                  </div>
                </a>
              </li>
            )
          })}
      </ul>
      <div className="flex items-center justify-between pt-6">
        {links && links.length && (
          <div className={s.megaCollectLinks}>
            {links.map((item, idx) => {
              return (
                <div key={idx}>
                  <a
                    className={s.megaCollectLink}
                    href={handleMenuUrl(
                      item.href,
                      is_custom_storefront,
                      'ref',
                      `navimenu_${current + 1}_${refidx}_${
                        listLen + idx + 1
                      }_copy`
                    )}
                  >
                    <span>{item.label}</span>
                  </a>
                </div>
              )
            })}
          </div>
        )}
        {!links && <div></div>}
        {moreLink && (
          <div>
            <Button className={s.megaCollectMore} variant="secondaryBlack">
              <a
                href={handleMenuUrl(
                  moreLink,
                  is_custom_storefront,
                  'ref',
                  `navimenu_${current + 1}_${refidx}_${
                    listLen + linksLen + 1
                  }_copy`
                )}
              ></a>
              <span>{shopCommon.see_more}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const MobNavProductItem = ({
  item,
  idx,
  thdtab,
  leftBarSubIdx,
  leftBarThdIdx,
  handleMenuUrl,
  leftBarIdx,
  is_custom_storefront,
}) => {
  const midIdx = thdtab
    ? `${leftBarSubIdx + 1}_${leftBarThdIdx + 1}`
    : leftBarSubIdx + 1
  return (
    <li className={s.mobNavProductItem}>
      <a
        href={handleMenuUrl(
          item.href || `/products/${item.sku}`,
          is_custom_storefront,
          'ref',
          `navimenu_${leftBarIdx + 1}_${midIdx}_${idx + 1}_img`
        )}
      >
        {item.imgtype === 'bg' ? (
          <div
            className={cn(
              s.mobNavProductImg,
              'bg-[length:100%] bg-center bg-no-repeat pt-[100%]'
            )}
            style={{ backgroundImage: `url(${item.img})` }}
          ></div>
        ) : (
          <Picture
            imgClassName={s.mobNavProductImg}
            loading="lazy"
            source={item.img}
            alt={item.title || item.label || ''}
            imgWidth={160}
            imgHeight={160}
          />
        )}
        <div className={s.mobNavProductInfo}>
          <p className={s.mobNavProductTitle}>
            {item.title || item.label || ''}
          </p>
          {item.description && (
            <Text
              className="mt-[4px] text-[12px] leading-[1.2] text-[#323232]"
              html={item.description}
            />
          )}
          {item.tag && <p className={s.mobNavProductTag}>{item.tag}</p>}
        </div>
      </a>
    </li>
  )
}

const MobNavCollectItem = ({
  item,
  idx,
  leftBarSubIdx,
  handleMenuUrl,
  leftBarIdx,
  is_custom_storefront,
}) => {
  const midIdx = leftBarSubIdx + 1
  return (
    <li className={s.mobNavProductCollectItem}>
      <a
        href={handleMenuUrl(
          item.href,
          is_custom_storefront,
          'ref',
          `navimenu_${leftBarIdx + 1}_${midIdx}_${idx + 1}_img`
        )}
      >
        <Picture
          imgClassName={s.mobNavProductCollectImg}
          loading="lazy"
          source={item.img}
          alt={item.title || ''}
          imgWidth={160}
          imgHeight={160}
        />
        <div className={s.mobNavProductInfo}>
          <p className={s.mobNavProductCollectTitle}>{item.title}</p>
          <p className={s.mobNavProductCollectDescription}>
            {item.description}
          </p>
        </div>
      </a>
    </li>
  )
}

const HeaderMobHead = ({
  level,
  title,
  setLeftBarShow,
  handleAddHash,
  headerSetting,
  handleMenuUrl,
  livestream,
  setLived,
  lived,
  setLeftBarSubTitle,
  leftBarSubTitle,
  setLeftBarThdTitle,
  setLeftBarSearchShow,
  locale,
  menuTheme,
  setSidebarView,
  toggleSidebar,
  itemsCount,
  setLeftBarSubIdx,
  setLeftBarThdIdx,
  handleRemoveHash,
  setFocus,
  CART,
  is_custom_storefront,
  isHomeDark,
}) => {
  return (
    <div className={classNames(s.fullWidth, s.headerMob)}>
      {level == 0 && (
        <div className="flex items-center gap-x-[12px]">
          <div
            className={s.headerMobMenuIcon}
            onClick={() => {
              setLeftBarShow(true)
              handleAddHash('navi')
            }}
          >
            <Icon
              iconKey={'navmenu'}
              width={23}
              height={23}
              className={s.icon}
              tag="Auto-00000114"
            ></Icon>
          </div>
          <LivePart
            headerSetting={headerSetting}
            handleMenuUrl={handleMenuUrl}
            livestream={livestream}
            setLived={setLived}
            lived={lived}
            is_custom_storefront={is_custom_storefront}
          />
        </div>
      )}
      {level == 1 && (
        <div>
          <div
            className={classNames(s.headerMobMenuIcon, 'opacity-0')}
            // onClick={() => setLeftBarSearchShow(true)}
          >
            <Icon
              iconKey={'navsearch'}
              width={20}
              height={20}
              className={s.icon}
            ></Icon>
          </div>
        </div>
      )}
      {level > 1 && (
        <div>
          <div
            className={s.headerMobMenuIcon}
            onClick={() => {
              if (level == 2) {
                handleAddHash('navi')
                setLeftBarSubTitle('')
              }
              if (level == 3) {
                handleAddHash(
                  `navi_2_${leftBarSubTitle.toLowerCase().replaceAll(' ', '_')}`
                )
                setLeftBarThdTitle('')
              }
              if (level == 4) setLeftBarSearchShow(false)
            }}
          >
            <Icon
              iconKey={'navback'}
              width={20}
              height={20}
              className={s.icon}
            ></Icon>
          </div>
        </div>
      )}
      <div className={s.headerMobMenuIcon}>
        {level <= 1 && (
          <a
            className={s.headerLogo}
            href={`${`/${locale === 'us' ? '' : locale}`}?ref=logo`}
            title={`${BRAND} ${locale}`}
            tag="Auto-00000116"
          >
            <Logo isHomeDark={isHomeDark} />
          </a>
        )}
        {level > 1 && <p className={s.headerMobSubTitle}>{title}</p>}
      </div>
      {level == 0 && (
        <div className="flex">
          <div
            tag="Auto-00000117"
            onClick={() => {
              setLeftBarShow(true)
              setLeftBarSearchShow(true)
              /* if (navSearch) {
                  setShowMobileSearchBoard(true)
                  setDropDownBoxContent('')
                  setDropDownSmallBoxContent('')
                  onSearchClick()
                } else {
                  setLeftBarShow(true)
                  setLeftBarSearchShow(true)
                } */
            }}
            className={`mr-5 ${s.headerMobMenuIcon}`}
          >
            <Icon
              iconKey={'navsearch'}
              width={20}
              height={20}
              className={s.icon}
            ></Icon>
          </div>
          <div className={s.headerMobMenuIcon}>
            {BRAND && (
              <>
                {CART && (
                  <>
                    {
                      <Button
                        tag="Auto-00000118"
                        className={classNames(
                          s.item,
                          {
                            [s.iconLight]: menuTheme,
                          },
                          'relative'
                        )}
                        variant="naked"
                        onClick={() => {
                          setSidebarView('CART_VIEW')
                          toggleSidebar()
                        }}
                        aria-label={`Cart items: ${itemsCount}`}
                      >
                        <Icon
                          iconKey={'carteufy'}
                          width={20}
                          height={20}
                          className={s.icon}
                        ></Icon>
                        {itemsCount > 0 && (
                          <span className={s.bagCount}>{itemsCount}</span>
                        )}
                      </Button>
                    }
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {level > 0 && (
        <div
          className={s.headerMobMenuIcon}
          onClick={() => {
            setLeftBarShow(false)
            setLeftBarSearchShow(false)
            setLeftBarSubTitle('')
            setLeftBarThdTitle('')
            setLeftBarSubIdx(0)
            setLeftBarThdIdx(0)
            handleRemoveHash()
            setFocus(false)
          }}
        >
          <Icon
            iconKey={'navclose'}
            width={20}
            height={20}
            className={s.icon}
          ></Icon>
        </div>
      )}
    </div>
  )
}

const HeaderMob = ({
  title,
  setLeftBarShow,
  handleAddHash,
  headerSetting,
  handleMenuUrl,
  livestream,
  setLived,
  lived,
  setLeftBarSubTitle,
  leftBarSubTitle,
  setLeftBarThdTitle,
  setLeftBarSearchShow,
  locale,
  menuTheme,
  setSidebarView,
  toggleSidebar,
  itemsCount,
  setLeftBarSubIdx,
  setLeftBarThdIdx,
  handleRemoveHash,
  setFocus,
  leftBarShow,
  leftBarThdTitle,
  shopCommon,
  leftBarSearchShow,
  searchValue,
  getLeftBarData,
  setLeftBarIdx,
  userCenterSetting,
  simpleUserCenterSetting,
  leftBarIdx,
  leftBarSubIdx,
  leftBarThdIdx,
  navSearch,
  recommendProducts,
  handle,
  handleSearch,
  customer,
  registrationsSettings,
  CART,
  setSearchValue,
  defaultSearchItem,
  searchResult,
  searching,
  is_custom_storefront,
  isHomeDark,
  nickname,
  username,
  profile,
}) => {
  return (
    <>
      <HeaderMobHead
        level={0}
        title={title}
        setLeftBarShow={setLeftBarShow}
        handleAddHash={handleAddHash}
        headerSetting={headerSetting}
        handleMenuUrl={handleMenuUrl}
        livestream={livestream}
        setLived={setLived}
        lived={lived}
        setLeftBarSubTitle={setLeftBarSubTitle}
        leftBarSubTitle={leftBarSubTitle}
        setLeftBarThdTitle={setLeftBarThdTitle}
        setLeftBarSearchShow={setLeftBarSearchShow}
        locale={locale}
        menuTheme={menuTheme}
        setSidebarView={setSidebarView}
        toggleSidebar={toggleSidebar}
        itemsCount={itemsCount}
        setLeftBarSubIdx={setLeftBarSubIdx}
        setLeftBarThdIdx={setLeftBarThdIdx}
        handleRemoveHash={handleRemoveHash}
        setFocus={setFocus}
        CART={CART}
        is_custom_storefront={is_custom_storefront}
        isHomeDark={isHomeDark}
      />
      <HeaderMobLeftBar
        leftBarShow={leftBarShow}
        leftBarSubTitle={leftBarSubTitle}
        leftBarThdTitle={leftBarThdTitle}
        shopCommon={shopCommon}
        leftBarSearchShow={leftBarSearchShow}
        setLeftBarShow={setLeftBarShow}
        handleAddHash={handleAddHash}
        headerSetting={headerSetting}
        handleMenuUrl={handleMenuUrl}
        livestream={livestream}
        setLived={setLived}
        lived={lived}
        setLeftBarSubTitle={setLeftBarSubTitle}
        setLeftBarThdTitle={setLeftBarThdTitle}
        setLeftBarSearchShow={setLeftBarSearchShow}
        locale={locale}
        username={username}
        nickname={nickname}
        menuTheme={menuTheme}
        setSidebarView={setSidebarView}
        toggleSidebar={toggleSidebar}
        itemsCount={itemsCount}
        setLeftBarSubIdx={setLeftBarSubIdx}
        setLeftBarThdIdx={setLeftBarThdIdx}
        handleRemoveHash={handleRemoveHash}
        setFocus={setFocus}
        searchValue={searchValue}
        getLeftBarData={getLeftBarData}
        setLeftBarIdx={setLeftBarIdx}
        userCenterSetting={userCenterSetting}
        simpleUserCenterSetting={simpleUserCenterSetting}
        leftBarIdx={leftBarIdx}
        leftBarSubIdx={leftBarSubIdx}
        leftBarThdIdx={leftBarThdIdx}
        navSearch={navSearch}
        recommendProducts={recommendProducts}
        handle={handle}
        handleSearch={handleSearch}
        customer={customer}
        registrationsSettings={registrationsSettings}
        CART={CART}
        setSearchValue={setSearchValue}
        defaultSearchItem={defaultSearchItem}
        searchResult={searchResult}
        searching={searching}
        is_custom_storefront={is_custom_storefront}
        profile={profile}
      />
    </>
  )
}

const HeaderMobLeftBar = ({
  leftBarShow,
  leftBarSubTitle,
  leftBarThdTitle,
  shopCommon,
  ...restProps
}) => {
  return (
    <div
      className={classNames(s.headerMobNav, `${leftBarShow ? s.actived : ''}`)}
    >
      <HeaderMobLeftSide
        level={1}
        leftBarSubTitle={leftBarSubTitle}
        leftBarThdTitle={leftBarThdTitle}
        shopCommon={shopCommon}
        {...restProps}
      />
      <HeaderMobLeftSide
        level={2}
        title={leftBarSubTitle}
        leftBarSubTitle={leftBarSubTitle}
        leftBarThdTitle={leftBarThdTitle}
        shopCommon={shopCommon}
        {...restProps}
      />
      <HeaderMobLeftSide
        level={3}
        title={leftBarThdTitle}
        leftBarSubTitle={leftBarSubTitle}
        leftBarThdTitle={leftBarThdTitle}
        shopCommon={shopCommon}
        {...restProps}
      />
      <HeaderMobLeftSide
        level={4}
        title={shopCommon?.['search']}
        leftBarSubTitle={leftBarSubTitle}
        leftBarThdTitle={leftBarThdTitle}
        shopCommon={shopCommon}
        {...restProps}
      />
    </div>
  )
}

const HeaderMobLeftSide = ({
  level,
  title,
  leftBarSubTitle,
  leftBarThdTitle,
  leftBarSearchShow,
  setLeftBarShow,
  handleAddHash,
  headerSetting,
  handleMenuUrl,
  livestream,
  setLived,
  lived,
  setLeftBarSubTitle,
  setLeftBarThdTitle,
  setLeftBarSearchShow,
  locale,
  menuTheme,
  setSidebarView,
  toggleSidebar,
  itemsCount,
  setLeftBarSubIdx,
  setLeftBarThdIdx,
  handleRemoveHash,
  setFocus,
  shopCommon,
  searchValue,
  getLeftBarData,
  setLeftBarIdx,
  userCenterSetting,
  simpleUserCenterSetting,
  leftBarIdx,
  leftBarSubIdx,
  leftBarThdIdx,
  navSearch,
  recommendProducts,
  handle,
  handleSearch,
  customer,
  registrationsSettings,
  CART,
  setSearchValue,
  defaultSearchItem,
  searchResult,
  searching,
  is_custom_storefront,
  nickname,
  username,
  profile,
}) => {
  return (
    <div
      className={`${s.headerMobNavContain} ${
        level > 1 ? s.headerNavAbsContain : ''
      } ${level == 2 && leftBarSubTitle ? s.actived : ''} ${
        level == 3 && leftBarThdTitle ? s.actived : ''
      } ${level == 4 && leftBarSearchShow ? s.actived : ''}`}
    >
      <HeaderMobHead
        level={level}
        title={title}
        setLeftBarShow={setLeftBarShow}
        handleAddHash={handleAddHash}
        headerSetting={headerSetting}
        handleMenuUrl={handleMenuUrl}
        livestream={livestream}
        setLived={setLived}
        lived={lived}
        setLeftBarSubTitle={setLeftBarSubTitle}
        leftBarSubTitle={leftBarSubTitle}
        setLeftBarThdTitle={setLeftBarThdTitle}
        setLeftBarSearchShow={setLeftBarSearchShow}
        locale={locale}
        menuTheme={menuTheme}
        setSidebarView={setSidebarView}
        toggleSidebar={toggleSidebar}
        itemsCount={itemsCount}
        setLeftBarSubIdx={setLeftBarSubIdx}
        setLeftBarThdIdx={setLeftBarThdIdx}
        handleRemoveHash={handleRemoveHash}
        setFocus={setFocus}
        CART={CART}
      />
      <HeaderMobBody
        customer={customer}
        level={level}
        shopCommon={shopCommon}
        searchValue={searchValue}
        getLeftBarData={getLeftBarData}
        locale={locale}
        headerSetting={headerSetting}
        handleMenuUrl={handleMenuUrl}
        setLeftBarIdx={setLeftBarIdx}
        setLeftBarSubTitle={setLeftBarSubTitle}
        handleAddHash={handleAddHash}
        userCenterSetting={userCenterSetting}
        setLeftBarSubIdx={setLeftBarSubIdx}
        setLeftBarThdIdx={setLeftBarThdIdx}
        setLeftBarThdTitle={setLeftBarThdTitle}
        leftBarIdx={leftBarIdx}
        leftBarSubIdx={leftBarSubIdx}
        leftBarThdIdx={leftBarThdIdx}
        navSearch={navSearch}
        recommendProducts={recommendProducts}
        handle={handle}
        handleSearch={handleSearch}
        setSearchValue={setSearchValue}
        defaultSearchItem={defaultSearchItem}
        searchResult={searchResult}
        searching={searching}
        username={username}
        nickname={nickname}
        is_custom_storefront={is_custom_storefront}
        profile={profile}
      />
      {/* <HeaderMobFooter
        level={level}
        registrationsSettings={registrationsSettings}
        locale={locale}
        customer={customer}
        handleMenuUrl={handleMenuUrl}
        userCenterSetting={userCenterSetting}
        simpleUserCenterSetting={simpleUserCenterSetting}
        headerSetting={headerSetting}
        is_custom_storefront={is_custom_storefront}
      /> */}
    </div>
  )
}

const HeaderMobBody = ({
  level,
  shopCommon,
  searchValue,
  getLeftBarData,
  locale,
  headerSetting,
  handleMenuUrl,
  setLeftBarIdx,
  setLeftBarSubTitle,
  handleAddHash,
  userCenterSetting,
  setLeftBarSubIdx,
  setLeftBarThdIdx,
  setLeftBarThdTitle,
  leftBarIdx,
  leftBarSubIdx,
  leftBarThdIdx,
  navSearch,
  recommendProducts,
  handle,
  handleSearch,
  setSearchValue,
  defaultSearchItem,
  searchResult,
  searching,
  is_custom_storefront,
  customer,
  nickname,
  username,
  profile,
}) => {
  const data = getLeftBarData(level)
  const { setRegistrationsPopup, setRegistrationsModelStatus } = useUI()
  const mobUserRightsIcons = [Icons?.EufyCredits, Icons?.Cart, Icons?.Coupons]
  return (
    <div className={s.headerMobNavBody}>
      {level == 1 && (
        <>
          <div className={s.fullWidth}>
            <div className={s.mobMainNav}>
              <ul className={s.mobMainList}>
                {headerSetting?.map((item, index) => {
                  const url = handleMenuUrl(
                    item.href,
                    is_custom_storefront,
                    'ref',
                    `navimenu_${index + 1}_copy`
                  )
                  if (item.type === 'links' || item.mobHide === true) return
                  return (
                    <li
                      key={index}
                      className={s.mobNavItem}
                      onClick={() => {
                        setLeftBarIdx(index)
                        setLeftBarSubTitle(item.label)
                        handleAddHash(
                          `navi_2_${item.label
                            .toLowerCase()
                            .replaceAll(' ', '_')}`
                        )
                      }}
                    >
                      {
                        <a
                          className={`${s.mobNavLink} ${
                            s[`mobLinkTypeof${item.type}`]
                          }`}
                          href={item.type === 'link' ? url : undefined}
                        >
                          <span>{item.label}</span>
                          {item.tag && (
                            <span className={s.headerTag}>{item.tag}</span>
                          )}
                        </a>
                      }
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className={s.fullWidth}>
            <div className={s.mobMainNav}>
              <ul className={classNames(s.mobMainList, s.headerBorderLine)}>
                {headerSetting?.map((item, index) => {
                  const url = handleMenuUrl(
                    item.href,
                    is_custom_storefront,
                    'ref',
                    `navimenu_${index + 1}_copy`
                  )
                  if (item.type !== 'links' || item.mobHide === true) return
                  return (
                    <li
                      key={index}
                      className={s.mobNavItem}
                      onClick={() => {
                        setLeftBarIdx(index)
                        setLeftBarSubTitle(item.label)
                        handleAddHash(
                          `navi_3_${item.label
                            .toLowerCase()
                            .replaceAll(' ', '_')}`
                        )
                      }}
                    >
                      {
                        <a
                          className={`${s.mobNavLink} ${
                            s[`mobLinkTypeof${item.type}`]
                          }`}
                          {...(url && { href: url })}
                        >
                          <span>{item.label}</span>
                          {item.tag && (
                            <span className={s.headerTag}>{item.tag}</span>
                          )}
                        </a>
                      }
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className={s.fullWidth}>
            <ul className={classNames(s.mobMainList, s.headerBorderLine)}>
              <li className={s.mobNavItem}>
                <a
                  className={`${s.mobNavLink}`}
                  href={handleMenuUrl(
                    userCenterSetting?.['orderTrackHref'],
                    is_custom_storefront,
                    'ref',
                    `navimenu_${headerSetting?.length + 1}_copy`
                  )}
                >
                  <span>{userCenterSetting?.['orderTrack']}</span>
                </a>
              </li>
              <li className={s.mobNavItem}>
                <a
                  className={`${s.mobNavLink}`}
                  href={handleMenuUrl(
                    userCenterSetting?.['referralHref'],
                    is_custom_storefront,
                    'ref',
                    `navimenu_${headerSetting?.length + 2}_copy`
                  )}
                >
                  <span>{userCenterSetting?.['get$40']}</span>
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
      {level == 2 && data.type == 'tab' && (
        <>
          <div className={s.fullWidth}>
            <div className={s.mobMainNav}>
              {
                <ul className={s.mobMainList}>
                  {data?.tabs?.map((tab, idx) => {
                    return (
                      <li
                        key={idx}
                        className={s.mobNavItem}
                        onClick={() => {
                          setLeftBarSubIdx(idx)
                          setLeftBarThdIdx(0)
                          setLeftBarThdTitle(tab.label)
                          handleAddHash(
                            `navi_3_${tab.label
                              .toLowerCase()
                              .replaceAll(' ', '_')}`
                          )
                        }}
                      >
                        <a className={`${s.mobNavLink} ${s.mobLinkTypeoftab}`}>
                          <span>{tab.label}</span>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              }
            </div>
          </div>
          <div className={s.fullWidth}>
            <ul className={`${s.mobMainList} ${s.headerBorderLine}`}>
              {data?.links?.map((link, idx) => {
                return (
                  <li key={idx} className={s.mobNavItem}>
                    <a
                      className={`${s.mobNavLink}`}
                      href={handleMenuUrl(
                        link.href,
                        is_custom_storefront,
                        'ref',
                        `navimenu_${leftBarIdx + 1}_${
                          data?.tabs?.length + idx + 1
                        }_copy`
                      )}
                    >
                      <span>{link.label}</span>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      )}
      {level == 2 &&
        data?.type == 'links' &&
        data?.group?.map((group, idx) => {
          return (
            <div key={idx} className={s.fullWidth}>
              <div className={`${idx > 0 && s.headerBorderLine}`}>
                <p className={s.megaTabTitle}>{group.label}</p>
                <ul className={s.mobMainList}>
                  {group?.links?.map((link, idx) => {
                    return (
                      <li key={idx} className={s.mobNavItem}>
                        <a
                          className={`${s.mobNavLink}`}
                          href={handleMenuUrl(
                            link.href,
                            is_custom_storefront,
                            'ref',
                            `navimenu_${leftBarIdx + 1}_${idx + 1}_copy`
                          )}
                        >
                          <span>{link.label}</span>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      {level == 2 && data?.type == 'deals' && (
        <div className={s.fullWidth}>
          <div className={s.mobDealsWrap}>
            {data?.links?.map((link, idx) => {
              const { links } = data
              return (
                <div
                  key={idx}
                  className={classNames(s.mobDealsItem, {
                    'text-[#fff]': link.theme === 'white',
                  })}
                >
                  <div className={s.megaDealsImgWrap}>
                    <Picture
                      imgClassName={classNames({
                        [s.mobDealsImg]: links.length > 1,
                        ['only-in-mobile']: links.length === 1,
                      })}
                      source={link.img}
                    ></Picture>
                    <Picture
                      imgClassName={classNames({
                        [s.mobDealsImg]: links.length > 1,
                        ['only-in-pc']: links.length === 1,
                        ['hidden']: links.length > 1,
                      })}
                      source={link.img_full}
                    ></Picture>
                  </div>
                  <div
                    className={classNames(s.megaDealsInfoWrap, {
                      [s.mobDealsFullInfo]: links.length === 1,
                    })}
                  >
                    <a
                      className={s.navBarFullLink}
                      href={handleMenuUrl(
                        link.href,
                        is_custom_storefront,
                        'ref',
                        `navimenu_${leftBarIdx + 1}_1_${idx + 1}_copy`
                      )}
                    ></a>
                    <p className={s.dealsTitle}>{link.title}</p>
                    <a
                      className={
                        link.theme === 'white' ? s.linkBtnWhite : s.linkBtn
                      }
                      href={handleMenuUrl(
                        link.href,
                        is_custom_storefront,
                        'ref',
                        `navimenu_${leftBarIdx + 1}_1_${idx + 1}_copy`
                      )}
                    >
                      {shopCommon.learn_more}
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {level == 3 && (
        <>
          {data?.collects && (
            <ul className={s.mobNavProductCollect}>
              {data.collects.map((item, idx) => (
                <MobNavCollectItem
                  key={idx}
                  idx={idx}
                  item={item}
                  leftBarSubIdx={leftBarSubIdx}
                  handleMenuUrl={handleMenuUrl}
                  leftBarIdx={leftBarIdx}
                  is_custom_storefront={is_custom_storefront}
                />
              ))}
            </ul>
          )}
          <div className="pt-6">
            <Swiper freeMode={true} slidesPerView={'auto'}>
              {data?.tabs?.map((tab, idx) => {
                return (
                  <SwiperSlide
                    className={`${s.mobProductTabItem} ${
                      idx == leftBarThdIdx && s.actived
                    }`}
                    key={idx}
                    onClick={() => {
                      setLeftBarThdIdx(idx)
                    }}
                  >
                    {tab.label}
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <div className={s.fullWidth}>
            <div className={s.mobNavProductContain}>
              {data?.list && (
                <div className={`${s.mobNavProductWrapper} ${s.actived}`}>
                  <ul className={s.mobNavProductList}>
                    {data?.list.map((item, idx) => {
                      return (
                        <MobNavProductItem
                          key={idx}
                          idx={idx}
                          item={item}
                          thdtab={false}
                          leftBarSubIdx={leftBarSubIdx}
                          leftBarThdIdx={leftBarThdIdx}
                          handleMenuUrl={handleMenuUrl}
                          leftBarIdx={leftBarIdx}
                          is_custom_storefront={is_custom_storefront}
                        />
                      )
                    })}
                  </ul>
                  <div className={s.mobNavCollections}>
                    <div className={s.mobNavProductLinks}>
                      {data?.links?.map((link, idx) => {
                        const listLen = data?.list?.length
                          ? data?.list?.length
                          : 0
                        return (
                          <a
                            className={s.megaCollectLink}
                            key={idx}
                            href={handleMenuUrl(
                              link.href,
                              is_custom_storefront,
                              'ref',
                              `navimenu_${leftBarIdx + 1}_${
                                leftBarSubIdx + 1
                              }_${listLen + idx + 1}_copy`
                            )}
                          >
                            {link.label}
                          </a>
                        )
                      })}
                    </div>
                    <div>
                      {data?.more && (
                        <Button
                          className={s.megaCollectMore}
                          variant="secondaryBlack"
                        >
                          <a
                            href={handleMenuUrl(
                              data.more,
                              is_custom_storefront,
                              'ref',
                              `navimenu_${leftBarIdx + 1}_${
                                leftBarSubIdx + 1
                              }_${
                                (data?.list?.length ?? 0) +
                                (data?.links?.length ?? 0) +
                                1
                              }_copy`
                            )}
                          ></a>
                          <span>
                            {shopCommon.see_more || shopCommon.check_more}
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {data?.tabs?.map((tab, idx) => {
                const moreLink = tab.more || data?.more
                return (
                  <div
                    key={idx}
                    className={`${s.mobNavProductWrapper} ${
                      idx == leftBarThdIdx && s.actived
                    }`}
                  >
                    <ul className={s.mobNavProductList}>
                      {tab.list.map((item, idx) => {
                        return (
                          <MobNavProductItem
                            key={idx}
                            idx={idx}
                            item={item}
                            thdtab={true}
                            leftBarSubIdx={leftBarSubIdx}
                            leftBarThdIdx={leftBarThdIdx}
                            handleMenuUrl={handleMenuUrl}
                            leftBarIdx={leftBarIdx}
                            is_custom_storefront={is_custom_storefront}
                          />
                        )
                      })}
                    </ul>
                    <div className={s.mobNavCollections}>
                      <div className={s.mobNavProductLinks}>
                        {tab?.links?.map((link, lidx) => {
                          const listLen = tab?.list?.length
                            ? tab?.list?.length
                            : 0
                          return (
                            <a
                              className={s.megaCollectLink}
                              key={lidx}
                              href={handleMenuUrl(
                                link.href,
                                is_custom_storefront,
                                'ref',
                                `navimenu_${leftBarIdx + 1}_${
                                  leftBarSubIdx + 1
                                }_${idx + 1}_${listLen + lidx + 1}_copy`
                              )}
                            >
                              {link.label}
                            </a>
                          )
                        })}
                      </div>
                      <div>
                        {moreLink && (
                          <Button
                            className={s.megaCollectMore}
                            variant="secondaryBlack"
                          >
                            <a
                              href={handleMenuUrl(
                                moreLink,
                                is_custom_storefront,
                                'ref',
                                `navimenu_${leftBarIdx + 1}_${
                                  leftBarSubIdx + 1
                                }_${idx + 1}_${
                                  (tab?.list?.length ?? 0) +
                                  (tab?.links?.length ?? 0) +
                                  1
                                }_copy`
                              )}
                            ></a>
                            <span>
                              {shopCommon.see_more || shopCommon.check_more}
                            </span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
      {level == 4 && (
        <div className={s.fullWidth}>
          <div className={s.mobSearchInputWrap}>
            <Icon
              iconKey={searching ? 'loading' : 'navsearch'}
              className={cn({ 'animate-spin': searching })}
              width={20}
              height={20}
            ></Icon>
            <input
              className={s.headerMobSearch}
              type="text"
              placeholder={
                defaultSearchItem || shopCommon?.['search_placeholder']
              }
              value={searchValue}
              onKeyUp={(e) => {
                e.key == 'Enter' &&
                  handleSearch(e.target.value || defaultSearchItem)
              }}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
            ></input>
            <Icon
              iconKey={'close'}
              width={20}
              height={20}
              onClick={() => setSearchValue('')}
            ></Icon>
          </div>
          {searchValue ? (
            <PredictiveSearch
              searchResult={searchResult}
              searchTerm={searchValue}
              viewMore={navSearch.viewMore}
              resultTitle={navSearch.result}
            />
          ) : null}
          {searchValue && !searching && searchResult?.items?.length === 0 ? (
            <Text
              variant="paragraph"
              className="my-[16px] w-full text-left text-[16px] font-medium text-[#777]"
            >
              {navSearch.noResult}
            </Text>
          ) : null}
          {!searchValue || (searchValue && !searchResult?.items?.length) ? (
            <RecommendProducts
              title={navSearch?.recommend_title}
              dataSource={recommendProducts}
              pageHandle={handle}
            />
          ) : null}
          {!searchValue && (
            <SearchHistory
              handleClick={handleSearch}
              locale={locale}
              title={navSearch?.searchHistory}
            />
          )}
        </div>
      )}
      <div className="mx-6 mt-6 min-md:mx-10 min-md:mt-10">
        <div
          style={{
            background:
              profile?.isPlus &&
              'linear-gradient(116deg, rgba(241, 234, 219, 0.80) 0%, rgba(217, 166, 106, 0.80) 145.94%), #FFF',
          }}
          className={cn(s.fullWidth, 'relative rounded-2xl py-6 min-md:py-10')}
        >
          <a
            className={s.signWrap}
            href={`${MULPASS_URL}/${locale}/account/profile/?tab=register`}
          >
            {profile ? (
              <div className={s.loggedIn}>
                <div className={s.userInfoBox}>
                  <div className="relative">
                    <Text className={s.userAvatar} html={nickname} />
                    {profile && !profile?.activated && (
                      <Icon
                        iconKey={'badge'}
                        width={20}
                        height={20}
                        className={'absolute -right-2 top-0 z-[1]'}
                      ></Icon>
                    )}
                    {profile?.isPlus && (
                      <Picture
                        className={s.plusIcon}
                        source={userCenterSetting?.plusIcon}
                      />
                    )}
                  </div>
                  <Text className={s.userName} html={username} />
                </div>
                <a
                  className={s.signOutText}
                  dangerouslySetInnerHTML={{
                    __html: userCenterSetting?.signOut,
                  }}
                  href={`${MULPASS_URL}/account/logout?app=${APP_NAME[locale]}`}
                />
                <Picture
                  className={s.logoIcon}
                  source={userCenterSetting?.logoIcon}
                />
              </div>
            ) : (
              <div className={s.signUpBox}>
                <Button
                  variant="plain"
                  className={cn(s.commonBtn, s.signUpBtn)}
                  onClick={() => {
                    setRegistrationsPopup(true)
                    setRegistrationsModelStatus('main')
                  }}
                >
                  {userCenterSetting?.['signUp']}
                </Button>
                <Text
                  className={s.existingUserText}
                  html={userCenterSetting?.existingUserText}
                  onClick={(e) => {
                    if (e?.target?.id === 'mobLogin') {
                      e.preventDefault()
                      setRegistrationsPopup(true)
                      setRegistrationsModelStatus('quickLogin')
                    }
                  }}
                />
                <div className="my-4 h-[1px] bg-[#EEE] min-md:my-6" />
                <div className={s.mobUserRights}>
                  {userCenterSetting?.mobUserRights?.map((item, i) => {
                    const IconComponent = mobUserRightsIcons?.[i]
                    return (
                      <div key={i + 'signUserItem'} className={s.signUserItem}>
                        {/* <Text html={item?.icon} className="[&_svg]:mx-auto" /> */}
                        {IconComponent ? (
                          <IconComponent className="mx-auto h-[18px] w-[18px]" />
                        ) : (
                          <div className="h-[18px] w-[18px]"></div>
                        )}
                        <Text
                          className="mt-2 text-[12px] font-medium leading-[1.2] text-[#333] min-md:text-[16px]"
                          html={item?.label}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </a>
        </div>
      </div>
    </div>
  )
}

const HeaderMobFooter = ({
  level,
  registrationsSettings,
  locale,
  customer,
  handleMenuUrl,
  userCenterSetting,
  simpleUserCenterSetting,
  headerSetting,
  is_custom_storefront,
}) => {
  const { setRegistrationsPopup, setRegistrationsModelStatus } = useUI()

  const handleRegistrations = useCallback(
    (e, type) => {
      if (!registrationsSettings) return
      e.preventDefault()
      setRegistrationsPopup(true)
      setRegistrationsModelStatus(type === 'signIn' ? 'main' : 'quickLogin')
    },
    [registrationsSettings, setRegistrationsPopup, setRegistrationsModelStatus]
  )
  return (
    level == 1 && (
      <div className={s.headerMobNavFooter}>
        <div className={classNames(s.headerBorderLine, s.fullWidth, 'py-5')}>
          <div className="flex items-center justify-between">
            <div className={s.headerMobMenuIcon}>
              {userCenterSetting && prof && (
                <a
                  href={`${MULPASS_URL}/account/?app=${APP_NAME[locale]}`}
                  aria-label="account"
                  className=" absolute left-0 top-0 z-[1] h-full w-full"
                ></a>
              )}

              {simpleUserCenterSetting && (
                <a
                  href={simpleUserCenterSetting?.loginUrl}
                  aria-label="account"
                ></a>
              )}
              <Icon
                iconKey={'navuser'}
                width={20}
                height={20}
                className={s.icon}
              ></Icon>
            </div>
            {!customer && (
              <div>
                <a
                  className={s.mobNavLoginLink}
                  href={handleMenuUrl(
                    `${MULPASS_URL}/?app=${APP_NAME[locale]}`,
                    is_custom_storefront,
                    'ref',
                    `navimenu_${headerSetting?.length + 3}_copy`
                  )}
                  // start_ai_generated
                  onClick={(e) => handleRegistrations(e, 'signIn')}
                  // end_ai_generated
                  aria-label={userCenterSetting?.['signIn']}
                >
                  {userCenterSetting?.['signIn']}
                </a>
                <a
                  className={s.mobNavLoginLink}
                  href={handleMenuUrl(
                    `${MULPASS_URL}/?app=${APP_NAME[locale]}&tab=register`,
                    is_custom_storefront,
                    'ref',
                    `navimenu_${headerSetting?.length + 4}_copy`
                  )}
                  // start_ai_generated
                  onClick={(e) => handleRegistrations(e, 'signUp')}
                  // end_ai_generated
                  aria-label={userCenterSetting?.['signUp']}
                >
                  {userCenterSetting?.['signUp']}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

const Navbar = ({ metafields, locale, recommendProducts, handle }) => {
  let {
    navSetting = {},
    headerSetting = [],
    userCenterSetting = {},
    shopCommon = {},
    livestream = {},
    simpleUserCenterSetting = {},
    registrationsSettings,
    navSearch,
  } = metafields
  const { query, pathname } = useRouter()
  const { toggleSidebar, setSidebarView, menuTheme, setMenuTheme } = useUI()
  const [current, setCurrent] = useState(100)
  const [currentChild, setCurrentChild] = useState(0)
  const [currentSubTab, setCurrentSubTab] = useState(0)
  const [showSearch, setShowSearch] = useState(false)
  const [leftBarShow, setLeftBarShow] = useState(false)
  const [leftBarSearchShow, setLeftBarSearchShow] = useState(false)
  const [leftBarIdx, setLeftBarIdx] = useState(0)
  const [leftBarSubIdx, setLeftBarSubIdx] = useState(0)
  const [leftBarThdIdx, setLeftBarThdIdx] = useState(0)
  const [leftBarSubTitle, setLeftBarSubTitle] = useState('')
  const [leftBarThdTitle, setLeftBarThdTitle] = useState('')
  const [lived, setLived] = useState(false)
  const { profile } = useProfile()
  const { data: customer } = useCustomer()
  const [searchValue, setSearchValue] = useState('')

  const [showSearchBoard, setShowSearchBoard] = useState(false)
  const [showMobileSearchBoard, setShowMobileSearchBoard] = useState(false)

  const [dropDownBoxOpen, setDropDownBoxOpen] = useState(false)

  const [dropDownBoxContent, setDropDownBoxContent] = useState('')

  const [dropDownSmallBoxContent, setDropDownSmallBoxContent] = useState('')

  const [focus, setFocus] = useState(false)

  const is_custom_storefront = true

  const { defaultSearchItem } = useDefaultSearchItem(
    shopCommon?.search_default_items ?? []
  )

  const [stableSearchResult, setStableSearchResult] = useState()

  const { data: searchResult, isLoading: searching } = useSearch({
    searchTerm: searchValue.trim(),
    locale,
  })

  const { bannerTheme } = useGlobal()

  const [scrollTop, setScrollTop] = useState(0)
  const [scrollDir, setScrollDir] = useState('up')

  const { scrollY } = useScroll()
  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (scrollTop > 400 && latest > scrollTop) {
      setScrollDir('down')
    } else {
      setScrollDir('up')
    }
    setScrollTop(latest)
  })

  const isHomeDark = useMemo(() => {
    return (
      handle === 'home' && scrollTop === 0 && !focus && bannerTheme === 'dark'
    )
  }, [handle, scrollTop, focus, bannerTheme])

  useEffect(() => {
    if (searchResult) {
      setStableSearchResult(searchResult)
    }
  }, [searchResult])

  useEffect(() => {
    if (leftBarShow) {
      document.querySelector('html').classList.add(s.lock)
      document.querySelector('html').classList.add('fw-hidden')
    } else {
      document.querySelector('html').classList.remove(s.lock)
      document.querySelector('html').classList.remove('fw-hidden')
    }
    if (navSetting?.theme === 'light') {
      setMenuTheme(true)
    } else {
      setMenuTheme(false)
    }
    if (query.style === 'new') {
      document.getElementById('headerV3').classList.add('header-v3-ab-new')
    }
    if (query.style === 'add') {
      document.getElementById('headerV3').classList.add('header-v3-ab-add')
    }
  }, [leftBarShow, navSetting?.theme, query.style, setMenuTheme])

  const handleAddHash = (hash) => {
    window.location.hash = hash
  }

  // start_ai_generated
  const handleRemoveHash = () => {
    const { history, location } = window
    if (history && location) {
      const { pathname, search } = location
      history.pushState('', document.title, pathname + search)
    }
  }
  // end_ai_generated

  const {
    setRegistrationsPopup,
    setRegistrationsModelStatus,
    setRegistrationsDealsType,
  } = useUI()

  const handleRegistrations = useCallback(
    (e, type) => {
      if (!registrationsSettings) return
      e.preventDefault()
      setRegistrationsPopup(true)
      // start_ai_generated
      setRegistrationsDealsType(
        registrationsSettings?.registrationsMain?.dealsType
      )
      // end_ai_generated
      setRegistrationsModelStatus(type === 'signIn' ? 'quickLogin' : 'main')
    },
    [
      setRegistrationsPopup,
      setRegistrationsModelStatus,
      registrationsSettings,
      setRegistrationsDealsType,
    ]
  )

  const CART = useMemo(() => commerceEnabled('cart', locale), [locale])

  const handleSearch = (val) => {
    storeSearchHistory(val, locale)
    location.assign(handleMenuUrl(`/search?q=${val}`, !!navSearch))
  }

  const getLeftBarData = (level) => {
    const subData =
      headerSetting?.filter((item) => item.label == leftBarSubTitle)?.[0] || {}
    const thdData =
      subData?.tabs?.filter((item) => item.label == leftBarThdTitle)?.[0] || {}

    if (level == 2) {
      return subData
    } else if (level == 3) {
      return thdData
    }
  }

  const { data } = useCart()

  const itemsCount = data?.lineItems?.reduce(countItem, 0) ?? 0

  const handleHideMega = () => {
    setCurrent(100)
    setCurrentChild(0)
    setCurrentSubTab(0)
    handleRemoveHash()
  }

  const setMenuParams = (url, label, val) => {
    if (url.includes('?')) {
      return `${url}&${label}=${val}`
    } else {
      return `${url}?${label}=${val}`
    }
  }

  const handleReferralTrack = async (e) => {
    e.preventDefault()
    try {
      await referralApi.uploadCommerceEvents(
        {
          menu: 'Navigation',
          sub: 'referral-new',
          type: 'click',
          name: 'Top',
        },
        locale
      )
      location.href = e.target.href
    } catch (error) {
      console.log(error)
    }
  }

  const nickname = useMemo(() => {
    const { firstName, email } = profile || {}
    return (firstName || email)?.charAt(0)?.toUpperCase()
  }, [profile])

  const username = useMemo(() => {
    const { first_name: firstName, email, last_name: lastName } = profile || {}
    const baseName =
      firstName && lastName ? firstName + lastName : email?.match(/^[^@]+/)[0]
    return baseName?.replace(/^(.{2}).{3}/, '$1***')
  }, [profile])

  const handleMenuUrl = (href, is_custom_storefront, label, val) => {
    return handleLocaleLink({ href, locale, is_custom_storefront, label, val })
  }

  return (
    <header
      className={cn(
        s.headerV3,
        'header-v3-ab-add',
        'header-v3-ab-new',
        navSetting?.className,
        {
          '!relative': pathname.includes('/products/'),
          '!z-[51] !transition-none': focus || leftBarShow,
          '!border-b-transparent !bg-transparent':
            handle === 'home' && scrollTop === 0 && !focus && !leftBarShow,
          '!text-white': isHomeDark && !(focus || leftBarShow),
          [s.down]: scrollDir === 'down' && handle === 'home',
          [s.up]: scrollDir === 'up',
        }
      )}
      id="headerV3"
      onMouseEnter={() => {
        setFocus(true)
      }}
      onMouseLeave={() => {
        setFocus(false)
      }}
    >
      <div className="relative z-20">
        <HeaderPc
          onRegistrations={handleRegistrations}
          searchValue={searchValue}
          locale={locale}
          headerSetting={headerSetting}
          setShowSearch={setShowSearch}
          shopCommon={shopCommon}
          showSearch={showSearch}
          handleSearch={handleSearch}
          setSearchValue={setSearchValue}
          navSearch={navSearch}
          recommendProducts={recommendProducts}
          handle={handle}
          userCenterSetting={userCenterSetting}
          simpleUserCenterSetting={simpleUserCenterSetting}
          customer={customer}
          nickname={nickname}
          username={username}
          handleMenuUrl={handleMenuUrl}
          handleReferralTrack={handleReferralTrack}
          menuTheme={menuTheme}
          setSidebarView={setSidebarView}
          toggleSidebar={toggleSidebar}
          itemsCount={itemsCount}
          livestream={livestream}
          setLived={setLived}
          lived={lived}
          setCurrent={setCurrent}
          handleAddHash={handleAddHash}
          current={current}
          currentChild={currentChild}
          setCurrentChild={setCurrentChild}
          setCurrentSubTab={setCurrentSubTab}
          currentSubTab={currentSubTab}
          CART={CART}
          defaultSearchItem={defaultSearchItem}
          searchResult={stableSearchResult}
          searching={searching}
          handleHideMega={handleHideMega}
          is_custom_storefront={is_custom_storefront}
          isHomeDark={isHomeDark}
          profile={profile}
        />
      </div>
      <HeaderMob
        setLeftBarShow={setLeftBarShow}
        handleAddHash={handleAddHash}
        headerSetting={headerSetting}
        handleMenuUrl={handleMenuUrl}
        livestream={livestream}
        setLived={setLived}
        lived={lived}
        nickname={nickname}
        username={username}
        setLeftBarSubTitle={setLeftBarSubTitle}
        leftBarSubTitle={leftBarSubTitle}
        setLeftBarThdTitle={setLeftBarThdTitle}
        setLeftBarSearchShow={setLeftBarSearchShow}
        locale={locale}
        menuTheme={menuTheme}
        setSidebarView={setSidebarView}
        toggleSidebar={toggleSidebar}
        itemsCount={itemsCount}
        setLeftBarSubIdx={setLeftBarSubIdx}
        setLeftBarThdIdx={setLeftBarThdIdx}
        handleRemoveHash={handleRemoveHash}
        setFocus={setFocus}
        leftBarShow={leftBarShow}
        leftBarThdTitle={leftBarThdTitle}
        shopCommon={shopCommon}
        leftBarSearchShow={leftBarSearchShow}
        searchValue={searchValue}
        getLeftBarData={getLeftBarData}
        setLeftBarIdx={setLeftBarIdx}
        userCenterSetting={userCenterSetting}
        simpleUserCenterSetting={simpleUserCenterSetting}
        leftBarIdx={leftBarIdx}
        leftBarSubIdx={leftBarSubIdx}
        leftBarThdIdx={leftBarThdIdx}
        navSearch={navSearch}
        recommendProducts={recommendProducts}
        handle={handle}
        handleSearch={handleSearch}
        customer={customer}
        registrationsSettings={registrationsSettings}
        CART={CART}
        setSearchValue={setSearchValue}
        defaultSearchItem={defaultSearchItem}
        searchResult={stableSearchResult}
        searching={searching}
        is_custom_storefront={is_custom_storefront}
        isHomeDark={isHomeDark}
        profile={profile}
      />
    </header>
  )
}

export default Navbar
